import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery";

export const userAddressApi = createApi({
  reducerPath: "userAddressApi",
  baseQuery: axiosBaseQuery({ baseUrl: "https://api.artvarium.az/api/v1" }),
  tagTypes: ["Address"],
  endpoints: (builder) => ({
    getUserAddress: builder.query({
      query: () => ({ url: "/user-adress", method: "GET" }),
      providesTags: ["Address"],
    }),
    addUserAddress: builder.mutation({
      query: ({ name, tel, address, region }) => ({
        url: "/user-add-address",
        method: "POST",
        data: { name, tel, address, region },
      }),
      invalidatesTags: ["Address"],
    }),
    deleteUserAddress: builder.mutation({
      query: (addressId) => ({
        url: `/user-delete-address/${addressId}`,
        method: "POST",
        data: addressId,
      }),
      invalidatesTags: ["Address"],
    }),
    updateUserAddress: builder.mutation({
      query: ({ name, tel, address, region }) => ({
        url: `/user-update-address/`,
        method: "POST",
        body: { name, tel, address, region },
      }),
      invalidatesTags: ["Address"],
    }),
    selectPrimaryAddress: builder.mutation({
      query: (addressId) => ({
        url: `/user-select-main-address/${addressId}`,
        method: "POST",
        data: addressId,
      }),
      invalidatesTags: ["Address"],
    }),
  }),
});

export const {
  useGetUserAddressQuery,
  useAddUserAddressMutation,
  useDeleteUserAddressMutation,
  useUpdateUserAddressMutation,
  useSelectPrimaryAddressMutation,
} = userAddressApi;
