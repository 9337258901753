import { configureStore } from "@reduxjs/toolkit";
import { cartApi } from "./redux/cartApi";
import { favApi } from "./redux/favApi";
import { userAddressApi } from "./redux/userAddressApi";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import cartReducer from "./redux/cartSlice";
import language from "./redux/languageSlice";
import { languageApi } from "./redux/languageApi";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, cartReducer);

export const store = configureStore({
  reducer: {
    [cartApi.reducerPath]: cartApi.reducer,
    [favApi.reducerPath]: favApi.reducer,
    [userAddressApi.reducerPath]: userAddressApi.reducer,
    [languageApi.reducerPath]: languageApi.reducer,
    cart: persistedReducer,
    language,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    })
      .concat(cartApi.middleware)
      .concat(favApi.middleware)
      .concat(userAddressApi.middleware)
      .concat(languageApi.middleware),
});

export const persistor = persistStore(store);
