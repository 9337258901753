import axios from "axios";
import { store } from "../store";
import { logoutUser } from "../redux/authActions";

let headers;
let baseURL;

if (process.env.NODE_ENV === "development") {
  baseURL = "https://api.artvarium.az/api/v1/";
} else if (process.env.NODE_ENV === "production") {
  baseURL = "https://api.artvarium.az/api/v1/";
}

headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  locale: "en",
};

const ApiService = axios.create({
  baseURL,
  timeout: 0,
  headers: headers,
});

// Request interceptor to add token and language headers
ApiService.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    const locale = sessionStorage.getItem("locale");
    if (locale) {
      config.headers["Language"] = locale;
    }
    const selectedLanguage = store.getState().language.lang;
    config.headers.set("lang", selectedLanguage);
    return config;
  },
  (error) => Promise.reject(error)
);

// Function to refresh the token
const refreshToken = async () => {
  try {
    const response = await axios.post(`${baseURL}/refresh-token`, {
      token: localStorage.getItem("refresh_token"),
    });
    const { accessToken, refreshToken } = response.data;
    localStorage.setItem("token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
    return accessToken;
  } catch (error) {
    logout(); // Call logout if the refresh fails
    return Promise.reject(error);
  }
};

// Function to handle user logout
const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  sessionStorage.removeItem("locale");
  store.dispatch(logoutUser());
  window.location.href = "/login";
};

// Response interceptor to handle token expiration
ApiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return ApiService(originalRequest);
        }
      } catch (refreshError) {
        logout(); // Logout if token refresh fails
        return Promise.reject(refreshError);
      }
    } else if (
      error.response &&
      (error.response.status === 403 || error.response.status === 419)
    ) {
      logout();
    }
    return Promise.reject(error);
  }
);

export default ApiService;
