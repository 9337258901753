import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery";

export const favApi = createApi({
  reducerPath: "favApi",
  baseQuery: axiosBaseQuery({ baseUrl: "https://api.artvarium.az/api/v1" }),
  tagTypes: ["Fav"],
  endpoints: (builder) => ({
    getFav: builder.query({
      query: () => ({ url: "/wishlist", method: "GET" }),
      providesTags: ["Fav"],
    }),
    addToFav: builder.mutation({
      query: (productId) => ({
        url: `/add-to-wishlist/${productId}`,
        method: "POST",
        data: productId,
      }),
      invalidatesTags: ["Fav"],
    }),
    removeFromFav: builder.mutation({
      query: (productId) => ({
        url: `/delete-from-wishlist/${productId}`,
        method: "POST",
        data: productId,
      }),
      invalidatesTags: ["Fav"],
    }),
  }),
});

export const { useGetFavQuery, useAddToFavMutation, useRemoveFromFavMutation } =
  favApi;
