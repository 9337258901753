import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Index = ({ children }) => {
  const [locales, setLocales] = useState([]);
  const location = useLocation();
  const [currentLocale, setCurrentLocale] = useState("az");
  const [path, setPath] = useState(location.pathname);

  const getPath = (locale) => {
    if (currentLocale === "az") {
      return locale.localePath + location.pathname;
    } else {
      return location.pathname.replace("/" + currentLocale, locale.localePath);
    }
  };

  useEffect(() => {
    const langRegex = /\/(en|ru)/;
    const locale = location.pathname.match(langRegex)?.[1] ?? "az";
    setCurrentLocale(locale);

    if (location.pathname.includes(locale)) {
      setPath(location.pathname.replace(currentLocale));
    }

    let newLocales = [];

    if (locale === "az") {
      newLocales = [
        {
          localePath: "/en",
          hrefLang: "en-us",
        },
        {
          localePath: "/ru",
          hrefLang: "ru",
        },
      ];
    }

    if (locale === "en") {
      newLocales = [
        {
          localePath: "",
          hrefLang: "az",
        },
        {
          localePath: "/ru",
          hrefLang: "ru",
        },
      ];
    }

    if (locale === "ru") {
      newLocales = [
        {
          localePath: "",
          hrefLang: "az",
        },
        {
          localePath: "/en",
          hrefLang: "en-us",
        },
      ];
    }

    setLocales(newLocales);
  }, [location]);

  return (
    <div>
      <meta charSet="utf-8" />
      {locales.map((locale) => {
        return (
          <link
            rel="alternate"
            href={"https:artvarium.az" + getPath(locale)}
            hrefLang={locale.hrefLang}
            key={locale.hrefLang}
          />
        );
      })}
      {children}
    </div>
  );
};

export default Index;
