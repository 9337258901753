import React, { Suspense, useEffect, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import "./index.scss";
import Loading from "./assets/loading.png";
import { v1 as uuidv1 } from "uuid";
import { setTranslates } from "./components/helpers";
import ApiService from "./api/axios";
import Layout from "./components/Layout";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { useGetTranslationQuery } from "./redux/languageApi";

const OrderHistory = React.lazy(() => import("./pages/Account/OrderHistory"));
const ProfileDetail = React.lazy(() => import("./pages/Account/ProfileDetail"));
const ChangePassword = React.lazy(() =>
  import("./pages/Account/ChangePassword")
);
const Support = React.lazy(() => import("./pages/Support/Support"));
const ProductDetail = React.lazy(() => import("./pages/ProductDetail"));
const Header = React.lazy(() => import("./components/Header/HeaderParent"));
const Footer = React.lazy(() => import("./components/Footer"));
const Home = React.lazy(() => import("./pages/Home"));
const Services = React.lazy(() => import("./pages/Services"));
const Blogs = React.lazy(() => import("./pages/Blogs"));
const BlogDetail = React.lazy(() => import("./pages/BlogDetail"));
const ServiceDetail = React.lazy(() => import("./pages/ServiceDetail"));
const Products = React.lazy(() => import("./pages/Products"));
const SubProducts = React.lazy(() => import("./pages/SubProducts"));
const Profile = React.lazy(() => import("./pages/Account/Profile"));
const Login = React.lazy(() => import("./pages/Authentification/Login"));
const Register = React.lazy(() => import("./pages/Authentification/Register"));
const Forgot = React.lazy(() => import("./pages/Authentification/Forgot"));
const About = React.lazy(() => import("./pages/About"));
const Stores = React.lazy(() => import("./pages/Stores"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Fav = React.lazy(() => import("./pages/Account/Fav"));
const Cart = React.lazy(() => import("./pages/Basket/Cart"));
const Campaigns = React.lazy(() => import("./pages/Campaigns/Campaigns"));
const CampaignDetail = React.lazy(() =>
  import("./pages/CampaignDetail/CampaignDetail")
);
const CartAddress = React.lazy(() => import("./pages/Basket/CartAddress"));
const Payment = React.lazy(() => import("./pages/Basket/Payment"));
const Error = React.lazy(() => import("./pages/Error"));
const CartSuccess = React.lazy(() => import("./pages/Basket/CartSuccess"));
const CartError = React.lazy(() => import("./pages/Basket/CartError"));
const Brands = React.lazy(() => import("./pages/Brands"));
const VideoGallery = React.lazy(() => import("./pages/VideoGallery"));

const App = ({ hideHeaderPaths = ["/error"] }) => {
  const { isLoading: translateLoading } = useGetTranslationQuery();
  const uuid = uuidv1();
  const { lang } = useSelector((state) => state.language);
  const { pathname, search } = useLocation();
  const [setting, setSetting] = useState("");
  const [menuCategories, setMenuCategories] = useState([]);
  const [favicon, setFavicon] = useState();
  const [seo, setSeo] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    const langRegex = /\/(en|ru)/;
    const savedLang = localStorage.getItem("art-lang");

    if (!savedLang) {
      localStorage.setItem("art-lang", "az");
    }

    const lang = savedLang || "az";
    const locale = pathname.match(langRegex)?.[1] ?? lang;

    if (lang === "az" && locale !== lang) {
      const newPath = pathname.replace(langRegex, "");
      return navigate(newPath + location.search);
    } else if (locale !== lang) {
      if (!langRegex.test(pathname.split("/")[1])) {
        return navigate(
          `/${lang}` + pathname.replace(langRegex, "") + location.search
        );
      }
      return navigate(
        pathname.replace(langRegex, `/${lang}`) + location.search
      );
    }
  }, [pathname]);

  const fetchLayouts = async () => {
    try {
      const resp = await ApiService.get("/layouts", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setSetting(resp.data.setting);
      setMenuCategories(resp.data.categories);
      setFavicon(resp.data.setting.favicon);
      setTranslates();
      if (localStorage.token) {
        localStorage.removeItem("guest_uuid", uuid);
      } else {
        localStorage.setItem("guest_uuid", uuid);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("guest_uuid");
        navigate("/login");
      } else {
        console.error("Failed to fetch layouts:", error);
      }
    }
  };
  const fetchHomePage = async () => {
    try {
      const resp = await ApiService.get("/homepage");
      setSeo(resp.data.seo);
    } catch (error) {
      console.error("Failed to fetch homepage:", error);
    }
  };
  useEffect(() => {
    fetchLayouts();
    handleAccessControl();
    fetchHomePage();
  }, []);

  function handleAccessControl() {
    const guestUUID = localStorage.getItem("guest_uuid");

    const token = localStorage.getItem("token");

    const currentPath = window.location.pathname;

    if (guestUUID && currentPath === "/profile") {
      window.location.href = "/login";
      return;
    }

    if (token && currentPath === "/login") {
      window.location.href = "/profile";
      return;
    }
  }

  const requireLogin = () => {
    let logged_in = false;
    if (localStorage.token) {
      logged_in = true;
    }
    if (logged_in) {
    } else {
      navigate("/login");
    }
  };

  if (translateLoading) {
    return (
      <div className="loading">
        <img src={Loading} alt="loading" />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <html lang={localStorage.getItem("art-lang")} />
        <meta charSet="utf-8" />
        <title>{seo.title}</title>
        <meta name="description" content={seo.meta_description} />
        <link
          rel="icon"
          type="image/png"
          href={setting.favicon}
          sizes="16x16"
        />
      </Helmet>
      <div className="App">
        <Suspense
          fallback={
            <div className="loading">
              <img src={Loading} alt="loading" />
            </div>
          }
        >
          {!hideHeaderPaths.includes(pathname) && (
            <Header allCategory={menuCategories} setting={setting} />
          )}
          <Link
            className="whatsApp"
            to={`https://wa.me/${setting.tel_whatsapp}`}
            target="_blank"
          >
            <svg
              fill="#fff"
              height="50px"
              width="50px"
              version="1.1"
              id="Layer_1"
              viewBox="0 0 308 308"
              stroke="#430D4B"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g id="XMLID_468_">
                  {" "}
                  <path
                    id="XMLID_469_"
                    d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348 c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458 C233.168,179.508,230.845,178.393,227.904,176.981z"
                  ></path>{" "}
                  <path
                    id="XMLID_470_"
                    d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z"
                  ></path>{" "}
                </g>{" "}
              </g>
            </svg>
          </Link>
          <Layout>
            <Routes>
              <Route exact path="/" element={<Home favicon={favicon} />} />
              <Route
                exact
                path="/about"
                element={<About favicon={favicon} />}
              />
              <Route exact path="/services" element={<Services />} />
              <Route exact path="/service/:slug" element={<ServiceDetail />} />
              <Route exact path="/blog" element={<Blogs favicon={favicon} />} />
              <Route exact path="/blog/:slug" element={<BlogDetail />} />
              <Route
                exact
                path="/products"
                element={<Products favicon={favicon} />}
              />
              <Route exact path="/sub-products" element={<SubProducts />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/forgot" element={<Forgot />} />
              <Route
                exact
                path="/brands"
                element={<Brands favicon={favicon} />}
              />
              <Route
                exact
                path="/video-gallery"
                element={<VideoGallery favicon={favicon} />}
              />
              <Route
                exact
                path="/campaigns"
                element={<Campaigns favicon={favicon} />}
              />
              <Route
                exact
                path="/campaigns/:slug"
                element={<CampaignDetail />}
              />

              <Route
                onenter={() => requireLogin()}
                exact
                path="/profile"
                element={<Profile />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/fav"
                element={<Fav />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/profile-detail"
                element={<ProfileDetail />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/change-password"
                element={<ChangePassword />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/order-history"
                element={<OrderHistory />}
              />
              <Route exact path="/product/:slug" element={<ProductDetail />} />
              <Route exact path="/stores" element={<Stores />} />
              <Route exact path="/stores/:slug" element={<Stores />} />
              <Route
                exact
                path="/contact"
                element={<Contact favicon={favicon} />}
              />
              <Route
                exact
                path="/support"
                element={<Support favicon={favicon} />}
              />
              <Route exact path="/cart" element={<Cart />} />
              {/* <Route exact path="/cart-address" element={<CartAddress />} />
            <Route exact path="/payment" element={<Payment />} /> */}
              <Route exact path="/success_payment" element={<CartSuccess />} />
              <Route exact path="/error_payment" element={<CartError />} />
              <Route exact path="*" element={<Error />} />
              <Route exact path="/en" element={<Home />} />
              <Route
                exact
                path="/en/about"
                element={<About favicon={favicon} />}
              />
              <Route
                exact
                path="/en/services"
                element={<Services favicon={favicon} />}
              />
              <Route
                exact
                path="/en/service/:slug"
                element={<ServiceDetail />}
              />
              <Route
                exact
                path="/en/blog"
                element={<Blogs favicon={favicon} />}
              />
              <Route exact path="/en/blog/:slug" element={<BlogDetail />} />
              <Route
                exact
                path="/en/products"
                element={<Products favicon={favicon} />}
              />
              <Route exact path="/en/sub-products" element={<SubProducts />} />
              <Route exact path="/en/login" element={<Login />} />
              <Route exact path="/en/register" element={<Register />} />
              <Route exact path="/en/forgot" element={<Forgot />} />
              <Route
                exact
                path="/en/brands"
                element={<Brands favicon={favicon} />}
              />
              <Route
                exact
                path="/en/video-gallery"
                element={<VideoGallery favicon={favicon} />}
              />
              <Route
                exact
                path="/en/campaigns"
                element={<Campaigns favicon={favicon} />}
              />
              <Route
                exact
                path="/en/campaigns/:slug"
                element={<CampaignDetail />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/en/profile"
                element={<Profile />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/en/fav"
                element={<Fav />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/en/profile-detail"
                element={<ProfileDetail />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/en/change-password"
                element={<ChangePassword />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/en/order-history"
                element={<OrderHistory />}
              />
              <Route
                exact
                path="/en/product/:slug"
                element={<ProductDetail />}
              />
              <Route
                exact
                path="/en/stores"
                element={<Stores favicon={favicon} />}
              />
              <Route exact path="/en/stores/:slug" element={<Stores />} />
              <Route
                exact
                path="/en/contact"
                element={<Contact favicon={favicon} />}
              />
              <Route
                exact
                path="/en/support"
                element={<Support favicon={favicon} />}
              />
              <Route exact path="/en/cart" element={<Cart />} />
              {/* <Route exact path="/cart-address" element={<CartAddress />} />
            <Route exact path="/payment" element={<Payment />} /> */}
              <Route
                exact
                path="/en/success_payment"
                element={<CartSuccess />}
              />
              <Route exact path="/en/error_payment" element={<CartError />} />
              <Route exact path="/ru" element={<Home favicon={favicon} />} />
              <Route
                exact
                path="/ru/about"
                element={<About favicon={favicon} />}
              />
              <Route
                exact
                path="/ru/services"
                element={<Services favicon={favicon} />}
              />
              <Route
                exact
                path="/ru/service/:slug"
                element={<ServiceDetail />}
              />
              <Route
                exact
                path="/ru/blog"
                element={<Blogs favicon={favicon} />}
              />
              <Route exact path="/ru/blog/:slug" element={<BlogDetail />} />
              <Route
                exact
                path="/ru/products"
                element={<Products favicon={favicon} />}
              />
              <Route exact path="/ru/sub-products" element={<SubProducts />} />
              <Route exact path="/ru/login" element={<Login />} />
              <Route exact path="/ru/register" element={<Register />} />
              <Route exact path="/ru/forgot" element={<Forgot />} />
              <Route
                exact
                path="/ru/brands"
                element={<Brands favicon={favicon} />}
              />
              <Route
                exact
                path="/ru/video-gallery"
                element={<VideoGallery favicon={favicon} />}
              />
              <Route
                exact
                path="/ru/campaigns"
                element={<Campaigns favicon={favicon} />}
              />
              <Route
                exact
                path="/ru/campaigns/:slug"
                element={<CampaignDetail />}
              />

              <Route
                onenter={() => requireLogin()}
                exact
                path="/ru/profile"
                element={<Profile />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/ru/fav"
                element={<Fav />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/ru/profile-detail"
                element={<ProfileDetail />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/ru/change-password"
                element={<ChangePassword />}
              />
              <Route
                onenter={() => requireLogin()}
                exact
                path="/ru/order-history"
                element={<OrderHistory />}
              />
              <Route
                exact
                path="/ru/product/:slug"
                element={<ProductDetail />}
              />
              <Route
                exact
                path="/ru/stores"
                element={<Stores favicon={favicon} />}
              />
              <Route exact path="/ru/stores/:slug" element={<Stores />} />
              <Route
                exact
                path="/ru/contact"
                element={<Contact favicon={favicon} />}
              />
              <Route
                exact
                path="/ru/support"
                element={<Support favicon={favicon} />}
              />
              <Route exact path="/ru/cart" element={<Cart />} />
              {/* <Route exact path="/cart-address" element={<CartAddress />} />
            <Route exact path="/payment" element={<Payment />} /> */}
              <Route
                exact
                path="/ru/success_payment"
                element={<CartSuccess />}
              />
              <Route exact path="/ru/error_payment" element={<CartError />} />
            </Routes>
          </Layout>
          {!hideHeaderPaths.includes(pathname) && (
            <Footer allCategory={menuCategories} setting={setting} />
          )}
        </Suspense>
      </div>
    </>
  );
};

export default App;
