import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../api/axios";

export const getTranslationList = createAsyncThunk(
  "getTranslationList",
  async (lang, thunkAPI) => {
    try {
      const response = await ApiService.get("/translation-list");
      return response.data;
    } catch (e) {}
  }
);

const languageSlice = createSlice({
  name: "language",
  initialState: {
    lang: localStorage.getItem("art-lang") ?? "az",
    translate: {},
  },
  reducers: {
    changeLanguage: (state, action) => {
      state.lang = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTranslationList.fulfilled, (state, action) => {});
  },
});

export default languageSlice.reducer;
export const LanguageActions = languageSlice.actions;
