import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery";

export const languageApi = createApi({
  reducerPath: "lang",
  baseQuery: axiosBaseQuery({ baseUrl: "https://api.artvarium.az/api/v1" }),
  tagTypes: ["lang"],
  endpoints: (builder) => ({
    getTranslation: builder.query({
      query: () => ({
        url: "/translation-list",
        method: "GET",
      }),
      transformResponse: (response) => {
        const translationObject = {};
        response.forEach((item) => {
          translationObject[item.key] = item.value;
        });
        return translationObject;
      },
    }),
  }),
});

export const { useGetTranslationQuery } = languageApi;
