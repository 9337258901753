import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery";

export const cartApi = createApi({
  reducerPath: "cartApi",
  baseQuery: axiosBaseQuery({ baseUrl: "https://api.artvarium.az/api/v1" }),
  tagTypes: ["Cart"],
  endpoints: (builder) => ({
    getCart: builder.query({
      query: () => ({ url: "/cart/list", method: "GET" }),
      providesTags: ["Cart"],
    }),
    addToCart: builder.mutation({
      query: (productId) => ({
        url: `/cart/add/${productId}`,
        method: "POST",
        data: productId,
      }),
      invalidatesTags: ["Cart"],
    }),
    removeFromCart: builder.mutation({
      query: (productId) => ({
        url: `/cart/delete/${productId}`,
        method: "POST",
        data: productId,
      }),
      invalidatesTags: ["Cart"],
    }),
    increaseCartItem: builder.mutation({
      query: (productId) => ({
        url: `/cart/plus/${productId}`,
        method: "POST",
        data: productId,
      }),
      invalidatesTags: ["Cart"],
    }),
    decreaseCartItem: builder.mutation({
      query: (productId) => ({
        url: `/cart/minus/${productId}`,
        method: "POST",
        data: productId,
      }),
      invalidatesTags: ["Cart"],
    }),
  }),
});

export const {
  useGetCartQuery,
  useAddToCartMutation,
  useRemoveFromCartMutation,
  useIncreaseCartItemMutation,
  useDecreaseCartItemMutation,
} = cartApi;
